export default function WhiteCurveSVG() {
    return (
        <div className="white-curve">
            <svg width="100%" viewBox="0 0 1440 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0)">
                    <rect width="1440" height="99.9765" transform="matrix(1 0 0 -1 0 100.046)" fill="transparent" />
                    <path d="M0 39.7199C0 39.7199 338.677 140.953 698.302 39.7199C1057.93 -61.5129 1440 65.0277 1440 65.0277V101.573H0V39.7199Z" fill="#FFFFFF" />
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="1440" height="99.9765" fill="transparent" transform="matrix(1 0 0 -1 0 100.046)" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    )
}
